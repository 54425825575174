import {
    GET_ITEM,
    GET_ITEMS,
    ITEM_ERROR,
    NEW_ITEM,
    DELETE_ITEM,
    CLEAR_ITEM,
    CLEAR_ITEMS
} from '../actions/types'

const initialState = {
    item: null,
    items: [],
    itemsLoading: true,
    itemLoading: true,
    error: null
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_ITEM:
            return {
                ...state,
                itemLoading: false,
                item: payload
            }
        case GET_ITEMS:
            return {
                ...state,
                itemsLoading: false,
                items: payload ? payload : []
            }
        case NEW_ITEM:
            return {
                ...state,
                itemsLoading: false,
                items: [payload, ...state.items],
                // itemLoading: false,
                // item: payload
            }
        case DELETE_ITEM:
            return {
                ...state,
                itemsLoading: false,
                items: state.items.filter(item => item !== payload),
                itemLoading: true,
                item: null
            }
        case ITEM_ERROR:
            return {
                ...state,
                error: payload
            }

        case CLEAR_ITEM:
            return {
                ...state, 
                itemLoading: true,
                item: null
            }
        case CLEAR_ITEMS:
            return {
                ...state, 
                itemsLoading: true,
                items: payload
            }
        default:
            return state;
    }
}