import axios from "axios";
import { setAlert } from "./alert";

import {
    GET_ITEM,
    GET_ITEMS,
    ITEM_ERROR,
    NEW_ITEM,
    DELETE_ITEM,
    CLEAR_ITEM,
    CLEAR_ITEMS
} from './types';

// NEW ITEM
export const newItem = (formdata) => async dispatch => {
    console.log(formdata);
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

    const body = JSON.stringify(formdata);

    try{
        const item = await axios.get(`/api/items/${formdata.barCode}`);
        if(item.data){
            dispatch(setAlert('该商品已经存在，请核对信息后重新发送。','danger'))
        }else{
            const res = await axios.post(
                // 'http://localhost:5000/api/items', 
                '/api/items',
                body, 
                config);
            console.log(res.data);
            setTimeout(()=>{dispatch({
                type: NEW_ITEM,
                payload: res.data
            })}, 3000)
            dispatch(setAlert('新建商品成功','success'))
        }
    }catch(err){
        dispatch({
            type: ITEM_ERROR,
            payload: err.response
        })      
    }
}

// SEARCH ITEM
export const searchItem = input => async dispatch => {

    try{
        const res = await axios.get(`/api/items/search/${input}`);
            dispatch({
                type: GET_ITEMS,
                payload: res.data
            })
    }catch(err){
        dispatch({
            type: ITEM_ERROR,
            payload: err.response
        })      
    }
}

// UPLOAD FILE
export const uploadFiles = (files, barCode) => async (dispatch) => {

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const photoRes = await axios.post(
      `/api/items/upload-files/${barCode}`,
      files,
      config
    );
    document.getElementById('waiting-spinner').classList.add('display-none');
    // setTimeout(()=>{window.close();},5000)
    dispatch(
      console.log(photoRes.data)
    );
    dispatch(setAlert(photoRes.data.msg, 'success'));

  } catch (err) {
    console.log(err);
    dispatch({
        type: ITEM_ERROR,
      payload: { msg: 'Files upload failed. 文件片上传失败', status: '500', err: err.response },
    });
    document.getElementById('waiting-spinner').classList.add('display-none');
  }
};

// GET ITEM
export const getItem = (barCode) => async dispatch => {
    try {
        // console.log(barCode)
        const res = await axios.get(`/api/items/${barCode}`);
        // const res = await axios.get(`http://localhost:5000/api/items/${id}`);
        // console.log('getItem called in action');
        // console.log(res.data)

        dispatch({
            type: GET_ITEM,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: ITEM_ERROR,
            payload: err.response
        })
    }
    
    document.getElementById('waiting-spinner').classList.add('display-none')
}

// GET ITEMS
export const getItems = () => async dispatch => {
    try {
        const res = await axios.get('/api/items');
        dispatch({
            type: GET_ITEMS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: ITEM_ERROR,
            payload: err.response
        })
    }
}

// DELETE ITEM
export const deleteItem = (id) => async dispatch => {
    // console.log("deleteItem called in action.")
      try{
          const res = await axios.delete(`/api/items/${id}`);
          // await axios.delete(`http://localhost:5000/api/items/${id}`);
  
          dispatch({
              type: DELETE_ITEM,
              payload: id
          });
        //   dispatch(setAlert(res.data.msg, "success"));
      }catch (err) {
          dispatch({
            type: ITEM_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status
            }
          });
        }
        document.getElementById('waiting-spinner').classList.add('display-none')
}

// CLEAR ITEM
export const clearItem = () => async dispatch => {
  try {
    dispatch({
      type: CLEAR_ITEM,
      payload: null
    })
  }catch(err){
    dispatch({
      type: ITEM_ERROR,
      payload: '清除商品数据失败'
    })
  }
}

// CLEAR ITEMS
export const clearItems = () => async dispatch => {
  try {
    dispatch({
      type: CLEAR_ITEMS,
      payload: []
    })
  }catch(err){
    dispatch({
      type: ITEM_ERROR,
      payload: '重置商品列表数据失败'
    })
  }
}
//  CREATE NEW ITEM (AUTOMATICLY GENERATED)
