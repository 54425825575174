import React from 'react'
import PropTypes from 'prop-types'
import ImagePlaceHolder from './imgPlaceholder.png'

const ItemsListItem = (item) => {
    const { coverImg, barCode, acgName, figure, title, numInStock } = item.item;
    // console.log(item.item);
  return (
    <tr className="items-table-item">
        <td scope='col' className="items-table-item-coverImg">
            <img src={coverImg ? `/uploads/images/${coverImg}` : ImagePlaceHolder} alt="图片" />
        </td>
        <td scope='col' className="items-table-item-barCode">{barCode}</td>
        <td scope='col' className="items-table-item-acgName">{acgName}</td>
        <td scope='col' className="items-table-item-figure">{figure}</td>
        <td scope='col' className="items-table-item-title">{title}</td>
        <td scope='col' className="items-table-item-numInStock">{numInStock}</td>
    </tr>
    )
}

ItemsListItem.propTypes = {
    item: PropTypes.object.isRequired
}

export default ItemsListItem