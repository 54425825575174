import {
    USER_INFO_LOADED,
    USER_LOADED,
    UPDATE_USER,
    USER_ERROR,
    CLEAR_USER,
    ALL_USERS,
  } from "../actions/types";
  
  const initialState = {
    user: null,
    userLoading: true,  
    users: [],
    usersLoading: true,
    error: null
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {

      case CLEAR_USER:
        return {
          ...state,
          user: null,
          userLoading: true
        }
      case USER_INFO_LOADED:
        return {
          ...state,
          userLoading: false,
          user: payload
        }
      case USER_LOADED:
        console.log(payload)
        return {
          ...state,
          userLoading: false,
          user: payload.user
        }
      case UPDATE_USER:
        return {
          ...state,
          userLoading: false,
          user: payload
        };
      case USER_ERROR:
        return {
          ...state,
          error: payload
        }
      case ALL_USERS:
        return {
          ...state,
          users: payload,
          usersLoading: false
        }
      default:
        return state;
    }
  }
  