import axios from "axios";
import { setAlert } from "./alert";
import { logout } from './auth';

import {
    USER_INFO_LOADED,
    USER_LOGINED,
    REGISTER_USER,
    // GET_USERS,
    USER_LOADED,
    UPDATE_USER,
    USER_ERROR,
    AUTH_ERROR,
    MANAGEMENT_GRANTED,
    DELETE_USER,
    LOGIN_SUCCESS,
    GET_FREE_VIP_QUALIFIED_COMMENTS_COUNT,
    // UPADTE_USER_ACCOUNTTYPE
    ALL_USERS
  } from "./types";

  import setAuthToken from "../utilities/setAuthToken";

// Register User
export const register = ({ username, password, email, accountType='normal' }) => async (dispatch) => {
    // console.log('register call in action');
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    const body = JSON.stringify({username, password, email, accountType});
    // console.log('body: ',body);
  
    try {
      const res = await axios.post(
        // "http://localhost:5000/api/users",
        '/api/users/',
        body,
        config
      );
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
      dispatch(loadUser());
  
      document.getElementById('register-box').classList.add('display-none')
    //   dispatch(loadUser());
    } catch (err) {
      const errors = err.response.data.errors;
      console.log(errors)
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: USER_ERROR,
        payload: errors
      });
    }

  };

  // Load User
export const loadUser = () => async (dispatch) => {
  const token = localStorage.getItem("IOtoken")
  if (token) {
    setAuthToken(token);
        
    try {
      const res = await axios.get("/api/users/user");
      // const res = await axios.get("http://localhost:5000/api/users/user");
      // console.log(res.data);
      const {user, isGranted, freeVipValidatedCommentsCount} = res.data;
      // const vipTimer = new Date(user.freeVipTimer);
      // const today = new Date();

      // if(vipTimer - today >= 172800000){
      //   const newUser = await axios.post('/api/users/update/stop-free-vip-timer')
      // }

      dispatch({
        type: USER_LOADED,
        payload:res.data
      });
      dispatch({
        type: USER_LOGINED,
        payload: {isAuthenticated: true, loading: false}
      })
    } catch (err) {
      // console.log(err)
      dispatch({
        type: AUTH_ERROR,
        payload: err.response,
      });
    }
  }else{
    dispatch({
      type: USER_ERROR,
      payload: {msg: '请登录账户'}
    })
  }
};



  // // Update User AccountType
  // export const updateUserAccountType = formData => async dispatch => {
  //   try {
  //     const config = {
  //       headers: {
  //           "content-type": "application/json"
  //       }
  //     };
  //     const res = await axios.post("/api/users/update/accountType", formData, config);
  //     dispatch({
  //       type: UPADTE_USER_ACCOUNTTYPE,
  //       payload: res.data
  //     })
  //   }catch(err){
  //     const errors = err.response;
  
  //     if (errors) {
  //       errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
  //     }
  
  //     dispatch({
  //       type: USER_ERROR,
  //       // payload: { msg: err.response.statusText, status: err.response.status }
  //       payload: err.response
  //     });
  //   }
  // }

  // Update User
  export const updateUser = (formData) => async dispatch => {
    console.log('formData recevied in updateUser: ', formData);
    try {
      const config = {
          headers: {
              "content-type": "application/json"
          }
      };
  
      // console.log("updateUser in action called. formData got in updateUser of actions: ",formData)
      const res = await axios.post("/api/users/update", formData, config);
      // const res = await axios.post("http://localhost:5000/api/users/update", formData, config);
      
      // console.log("res.body got from updateUser: ", res.data)
      dispatch({
          type: UPDATE_USER,
          payload: res.data
      });
      dispatch(setAlert('用户信息已更新','success'))
  }catch (err) {
      const errors = err.response;
  
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      }
  
      dispatch({
        type: USER_ERROR,
        // payload: { msg: err.response.statusText, status: err.response.status }
        payload: err.response
      });
    }
    // document.getElementById('waiting-spinner').classList.add('display-none')
  } 

  // Update User Password when logged in;
export const updatePasswordWithOld = (formData) => async dispatch => {
    
    try {
      const config = {
          headers: {
              "content-type": "application/json"
          }
      };

      const body = JSON.stringify(formData);
  
      // console.log("updateUser in action called. formData got in updateUser of actions: ",formData)
      const res = await axios.post("/api/users/update_pw/o", body, config);
      // const res = await axios.post("http://localhost:5000/api/users/update_pw/o", formData, config);
      
      // console.log("res.body got from updateUser: ", res.data)
      // dispatch({
      //     type: UPDATE_USER,
      //     payload: res.data
      // });

      dispatch(setAlert(res.data.msg, 'success'));
      setTimeout(()=>{ 
      dispatch(
        logout()
      );
      }, 2000)

  }catch (err) {
      // const errors = err.response;
  
      // if (errors) {
      //   errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      // }
      console.log(err.response)
      if(err.response){dispatch(setAlert(err.response.data.msg,'danger'))}
      dispatch({
        type: USER_ERROR,
        // payload: { msg: err.response.statusText, status: err.response.status }
        payload: err.response
      });
    }
    // document.getElementById('waiting-spinner').classList.add('display-none')
  }

  
  // Update User Password when logged in;
export const updatePasswordWithoutOld = (formData) => async dispatch => {
    
  try {
    const config = {
        headers: {
            "content-type": "application/json"
        }
    };

    // console.log("updateUser in action called. formData got in updateUser of actions: ",formData)
    const res = await axios.post("/api/users/update_pw/s", formData, config);
    // const res = await axios.post("http://localhost:5000/api/users/update_pw/s", formData, config);
    
    // console.log("res.body got from updateUser: ", res.data)
    dispatch({
        type: UPDATE_USER,
        payload: res.data
    });
}catch (err) {
    const errors = err.response;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: USER_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
      payload: err.response
    });
  }
  // document.getElementById('waiting-spinner').classList.add('display-none')
}

// Delete User
export const deleteUser = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/users/${id}`)
    // const res = await axios.delete(`http://localhost:5000/api/users/${id}`)
    console.log(res.data);
    const { msg, deletedID } = res.data;
    dispatch({
      type: DELETE_USER,
      payload: deletedID
    })
    dispatch(setAlert(msg, 'success'));
  }catch(err){
    const errors = err.response;

    if (errors) {
      // errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
      console.log(errors)
    }

    dispatch({
      type: USER_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
      payload: err.response
    });
  }
}

// Get all user accounts for given company
export const getAllUsers = () => async dispatch => {
  try {
    // const res = await axios.get( `/api/users/allAccounts/${company}`);
    const res = await axios.get('/api/users')

    dispatch({
      type: ALL_USERS,
      payload: res.data
    })
  }catch(err){
    dispatch({
      type: USER_ERROR,
      payload: err.response
    })
  }
}