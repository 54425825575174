import React from 'react'

import Spinner from './Spinner';

const WaitingSpinner = () => {
    return (
        <div className="waiting-spinner display-none" id="waiting-spinner">
            <div className="waiting-spinner-inner-wrapper">
                <Spinner />
                <p className="text-align-center">
                    数据更新中，请稍后
                </p>
            </div>
        </div>
    )
}

export default WaitingSpinner;