import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Link , useNavigate} from 'react-router-dom'
import { connect } from 'react-redux'

import ItemsListItem from '../layout/ItemsListItem'

 const Main = ({isAuthenticated, items}) => {    
    const [ searchText, setSearchText ] = useState('')
    const [ filteredItems, setFilteredItems ] = useState()
    const navigate = new useNavigate();
    setTimeout(()=>{
        if(!isAuthenticated){ navigate('/') }
    }, 1000)

    const onTextSearch = e => {
        e.preventDefault();
        setSearchText(e.target.value);
    }

    const onClearInput = (e) => {
        e.preventDefault();
        setSearchText('');
    }

    useEffect(()=>{
        if(searchText){
            setFilteredItems(items.filter(item => item.barCode.includes(searchText) || item.acgName.includes(searchText) || item.figure.includes(searchText) || item.title.includes(searchText)))
        }else{
            setFilteredItems([])
        }
    },[searchText])
  return (
    <div className='container'>
        <div className="grid-two my-5">
            <div className="box">

            <Link className='main-entry-link' to={'/items'}>品项管理</Link>
            </div>
            <div className="box">

            <Link className='main-entry-link' to={'/transactions'}>条目录入</Link>
            </div>
        </div>
        {/* <div className="display-flex"> */}
            <form className='search-form' id='fuzzy-search-form'>
            <label htmlFor="fuzzy-search-form-input">搜索商品：</label>
            <input type="text" name="fuzzy-search-form-input" id="fuzzy-search-form-input" onChange={e => onTextSearch(e)} value={searchText}/>
            <button className='btn btn-primary' onClick={e => onClearInput(e)}>重置</button>
            {/* <p className="text-small"></p> */}
            {/* <button>搜索</button> */}
        </form>
        {/* </div> */}
        {filteredItems && filteredItems.length > 0 ? <div className="">
            <table className="items-table">
            <thead className="items-table-head">
                <tr className="items-table-item">
                    <th scope='col' className="items-table-item-coverImg">图片</th>
                    <th scope='col' className="items-table-item-barCode">条形码</th>
                    <th scope='col' className="items-table-item-acgName">番名</th>
                    <th scope='col' className="items-table-item-figure">人物</th>
                    <th scope='col' className="items-table-item-title">名称</th>
                    <th scope='col' className="items-table-item-barCode">库存</th>
                </tr>
            </thead>
            <tbody>
                 {filteredItems.map(item => 
                    <ItemsListItem item={item}  key={item._id}/>
                )} 
            </tbody>
        </table>
            

            
        </div> : ''}  
    </div>
  )
}

Main.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    // searchItem: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    items: state.item.items
})

export default connect(mapStateToProps)(Main)