import {
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    USER_LOGINED
  } from "../actions/types";
  
  const initialState = {
    token: localStorage.getItem("IOtoken"),
    isAuthenticated: false,
    loading: true,
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case LOGIN_SUCCESS:
        localStorage.setItem("IOtoken", payload.token);
        return {
          ...state,
          ...payload,
          isAuthenticated: true,
          loading: false,
        };
      case USER_LOGINED:
        return {
          ...state,
          ...payload
        }
      case LOGIN_FAIL:
        // case ACCOUNT_DELETED:
        // localStorage.removeItem("IOtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: true,
        };
      case LOGOUT:
        localStorage.removeItem("IOtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          loading: true,
        };
      case AUTH_ERROR:
        localStorage.removeItem("IOtoken");
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          error: payload
        };
      default:
        return state;
    }
  }
  