// ----- ALERT -----
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//ANNOUNCEMENT
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR';
export const NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

// AUTH
export const USER_INFO_LOADED = 'USER_INFO_LOADED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const MANAGEMENT_GRANTED = 'MANAGEMENT_GRANTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOGINED = 'USER_LOGINED';

// ITEM
export const GET_ITEM = 'GET_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const ITEM_ERROR = 'ITEM_ERROR';
export const ITEMS_ERROR = 'ITEMS_ERROR';
export const NEW_ITEM = 'NEW_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const CLEAR_ITEM = 'CLEAR_ITEM';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';

//NOTIFICATION
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

//TRANSACTION
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const NEW_TRANSACTION = 'NEW_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const GET_AGENT_TRANSACTIONS = 'GET_AGENT_TRANSACTIONS';
export const APPROVE_TRANSACTION = 'APPROVE_TRANSACTION';
export const FILTER_TRANSACTIONS = 'FILTER_TRANSACTIONS';

//SECURITYQUESTION
// export const GET_SECURITYQUESTION = 'GET_SECURITYQUESTION';
// export const GET_SECURITYQUESTIONS = 'GET_SECURITYQUESTIONS';
// export const SECURITYQUESTION_ERROR = 'SECURITYQUESTION_ERROR';
// export const SECURITYQUESTIONS_ERROR = 'SECURIRYQUESTIONS_ERROR';
// export const NEW_SECURITYQUESTION = 'NEW_SECURITYQUESTION';
// export const DELETE_SECURITYQUESTION = 'DELETE_SECURITYQUESTION';

// USER
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_NEW_USER_ERROR = 'CREATE_NEW_USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';
export const NEW_USER = 'NEW_USER';
export const ALL_USERS = 'ALL_USERS';


// FILES
export const UPLOAD_VIDEOS = 'UPLOADS_VIDEO';
export const VIDEOS_ERROR = 'VIDEOS_ERROR';
export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS';
export const PHOTOS_ERROR = 'PHOTOS_ERROR';


// OTHER MANAGEMENT
// export const GET_AGENTS = 'GET_AGENTS';
// export const GET_AGENTS_ERROR = 'GET_AGENTS_ERROR';
// export const GET_USERS_COUNT = 'GET_USERS_COUNT';
// export const USERS_COUNT_ERROR = 'USERS_COUNT_ERROR';
// export const UPADTE_USER_ACCOUNTTYPE = 'UPADTE_USER_ACCOUNTTYPE';
// export const UPDATE_USER_ACCOUNTTYPE_ERROR = 'UPDATE_USER_ACCOUNTTYPE_ERROR';
// export const GET_USERS = 'GET_USERS';
// export const GET_USERS_ERROR = 'GET_USERS_ERROR';
// export const GET_TRANSACTIONS_MANAGEMENT = 'GET_TRANSACTIONS_MANAGEMENT';
// export const TRANSACTIONS_MANAGEMENT_ERROR = 'TRANSACTIONS_MANAGEMENT_ERROR';
// export const TOTAL_INCOME = 'TOTAL_INCOME';
// export const TOTAL_INCOME_ERROR = 'TOTAL_INCOME_ERROR';
// export const WITHDRAW_ERROR = 'WITHDRAW_ERROR';
// export const DOWNLOAD_DANCERS = 'DOWNLOAD_DANCERS';
