import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { filterTransactions, getTransactions, newTransaction } from '../../actions/transaction'
import { clearItem, getItem } from '../../actions/item';
import WaitingSpinner from '../layout/WaitingSpinner'
import ImagePlaceHolder from '../layout/imgPlaceholder.png'
import TransactionsList from '../layout/TransactionsList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { getAllUsers } from '../../actions/user'

const Transactions = ({
    users, 
    usersLoading, 
    item, 
    itemLoading, 
    transactions, 
    transactionsLoading, 
    getTransactions, 
    getAllUsers, 
    getItem, 
    newTransaction, 
    isAuthenticated, 
    clearItem, 
    filterTransactions
}) => {

    const navigate = new useNavigate();
    setTimeout(()=>{
        if(!isAuthenticated){ navigate('/') }
    }, 1000)

    const [ transactionsStatistic, setTransacntionsStatistic] = useState({        
        inTransactions:[],
        inTotalPrice:0,
        inShippiongCost:0,
        outTransactions:[],
        outTotalPrice:0,
        outShippingCost:0
    })


    const [formData, setFormData] = useState({
        barCode: '',
        activity:'in',
        price: 0,
        count: 1,
        shippingCost: 0,
        channel: '国内'
    })

    const [filterFormData, setFilterFormData] = useState({
        startDate: '',
        endDate: '',
        operator: 'all',
        activity: 'all',
        channel: '',
    })

    const { barCode, activity, price, count, shippingCost, channel } = formData;

    useEffect(()=>{
        getTransactions();
        getAllUsers();
    },[
        getTransactions,
        getAllUsers
    ])

    useEffect(()=>{
        console.log(formData)
        if(barCode){
            getItem(barCode)
        }       
    },[formData]) 

    useEffect(()=>{
        console.log(filterFormData);
    },[filterFormData])

    useEffect(()=>{
        if(transactions && transactions.length > 0){
            const inTransactions = transactions.filter(item => item.activity === 'in');
            const outTransactions = transactions.filter(item => item.activity === 'out');
            // inTransactions.reduce((a,b) => console.log(a.price, b.price))
            const inTotalPrice = inTransactions.reduce((a,b)=> a + b.price * b.count, 0);
            const outTotalPrice = outTransactions.reduce((a,b)=> a + b.price * b.count, 0)
            setTransacntionsStatistic({
                ...transactionsStatistic,
                inTransactions: inTransactions.reduce((a,b) => a + b.count, 0),
                // inTotalPrice: inTransactions.reduce((a,b)=> a.price * a.count + b.price * b.count, 0),
                inTotalPrice: inTotalPrice,
                inShippingCost: inTransactions.reduce((a,b) => a + b.shippingCost, 0),
                outTransactions: outTransactions.reduce((a,b) => a + b.count, 0),
                // outTotalPrice: outTransactions.reduce((a,b)=> a.price * a.count + b.price * b.count, 0),
                outTotalPrice: outTotalPrice,
                outShippingCost: outTransactions.reduce((a,b) => a + b.shippingCost, 0)
            })
        }
    },[transactions, transactionsLoading])

    useEffect(()=>{},[itemLoading, item, users, usersLoading])

    const onFormChange = e => {
        e.preventDefault();
        const element = e.target.id
        const field = element.split('-')[0]
        // switch(field){
        //     case 'barCode':
        //         getItem(barCode);
        // }
        setFormData({
            ...formData,
            [field]: e.target.value
        })
        
    }

    const onFormSubmit = e => {
        e.preventDefault();
        newTransaction({
            item: barCode,
            count: count,
            price: price,
            activity: activity,
            shippingCost: shippingCost,
            channel: channel
        })
        setTimeout(()=>{
            clearItem();
            setFormData({
                barCode: '',
                activity:'in',
                price: 0,
                count: 1,
                shippingCost: 0,
                channel: '国内'
            })
        },1000)
    }

    const onFilterFormChange = e => {
        const value = e.target.value;
        const field = e.target.name.split('-')[1];
        setFilterFormData({...filterFormData, [field]: value})
        // console.log('field: ', field, '; value: ', value);
    }

    const onFilterSubmit = e => {
        e.preventDefault();
        // console.log("onFilterSubmit pressed");
        filterTransactions(filterFormData)
    }

    const onFilterReset = e => {
        e.preventDefault();
        // console.log('onFilterReset pressed');
        setFilterFormData({
            startDate: '',
            endDate: '',
            operator: 'all',
            activity: 'all',
            channel: '',
        })
        getTransactions();
    }

  return !transactionsLoading ? 
    <div className='container'>
        <Link to='/main' className='navigate-link' onClick={e => clearItem()}><FontAwesomeIcon icon={faArrowLeft}/> 返回</Link>
        <h1 className='text-align-center bg-blue text-white'>条目录入</h1>
        {!itemLoading && item ? <table className="items-table my-2">
            <thead className="items-table-head">
                <tr className="items-table-item">
                    <th scope='col' className="items-table-item-coverImg">图片</th>
                    <th scope='col' className="items-table-item-barCode">条形码</th>
                    <th scope='col' className="items-table-item-acgName">番名</th>
                    <th scope='col' className="items-table-item-figure">人物</th>
                    <th scope='col' className="items-table-item-title">名称</th>
                    <th scope='col' className="items-table-item-barCode">库存</th>
                </tr>
            </thead>
            <tbody>
                <tr className="items-table-item">
                    <td scope='col' className="items-table-item-coverImg">
                        <img src={item.coverImg ? `/uploads/images/${item.coverImg}` : ImagePlaceHolder} alt="图片" />
                    </td>
                    <td scope='col' className="items-table-item-barCode">{item.barCode}</td>
                    <td scope='col' className="items-table-item-acgName">{item.acgName}</td>
                    <td scope='col' className="items-table-item-figure">{item.figure}</td>
                    <td scope='col' className="items-table-item-title">{item.title}</td>
                    <td scope='col' className="items-table-item-numInStock">{item.numInStock}</td>
                </tr>  
            </tbody>
        </table> : ''}
        <form className='entry-form pb-2 mb-2 border-bottom' id='entry-form' onSubmit={e => onFormSubmit(e)}>
            <div className="mb-1">
                <label htmlFor="barCode-input">条形码：</label>
                <input type="text" className='long-input' name="barCode-input" id="barCode-input" value={barCode} onChange={e => onFormChange(e)}/>
            </div>
            <div className="display-flex">
                <select name="activity-select" id="activity-select" className={activity === 'in' ? 'background-blue' : 'background-green'}value={activity} onChange={e => onFormChange(e)}>
                    <option value="in">入库</option>
                    <option value="out">出库</option>
                </select>
                <label htmlFor="channel-input">渠道：</label>
                <input type="number" name="channel-input" id="channel-input" value={channel} onChange={e => onFormChange(e)}/>
                <label htmlFor="count-input">数量：</label>
                <input type="number" name="count-input" id="count-input" value={count} onChange={e => onFormChange(e)}/>
                <label htmlFor="price-input">价格：</label>
                <input type="number" name="price-input" id="price-input" value={price} onChange={e => onFormChange(e)}/>
                <label htmlFor="shippingCost-input">运输成本：</label>
                <input type="number" name="shippingCost-input" id="shippingCost-input" value={shippingCost} onChange={e => onFormChange(e)}/>
                <button className='btn btn-primary ml-3' onClick={e => onFormSubmit(e)}>录入</button>
            </div>

        </form>
        {/* 需要提前拿到同公司的所有账户并装填入账户选择select的option中 */}
        <form className="entry-form pb-2 mb-2 border-bottom" id="transaction-filter" onSubmit={e => onFilterSubmit(e)} onChange={e => onFilterFormChange(e)}>
            <h3>出入库记录筛选：</h3>
            <div className="display-flex">
                <label htmlFor="transaction-startDate">起始时间：</label>
                <input type="date" name="transaction-startDate" id="transaction-startDate" value={filterFormData.startDate} />
                <label htmlFor="transaction-endDate">截止时间：</label>
                <input type="date" name="transaction-endDate" id="transaction-endDate" value={filterFormData.endDate} />
                <label htmlFor="transaction-channel">渠道：</label>
                <input type="text" name="transaction-channel" id="transaction-channel" value={filterFormData.channel}/>
                
            </div>
            <div className="display-flex mt-1">
                <label htmlFor="transaction-operator">操作员：</label>
                <select name="transaction-operator" id="transaction-operator" defaultValue='0' value={filterFormData.operator} className='select-fit-content text-black'>
                    <option value="all">不限</option>
                    {!usersLoading ? users.map(user => <option value={user.username}>{user.username}</option>) : ''}
                    {/* 
                    {accounts.map(item => <option value={item.username}>{item.username}</option>)}
                    */}
                </select>
                <label htmlFor="transaction-activity">操作：</label>
                <select name="transaction-activity" id="transaction-activity" className='select-fit-content text-black' defaultValue='all' value={filterFormData.activity}>
                    <option value="all">不限</option>
                    <option value="in">入库</option>
                    <option value="out">出库</option>
                </select>
            </div>
            <div className="mt-1">
                <button onClick={e => onFilterSubmit(e)} className='btn btn-primary ml-1'>筛选</button>
                <button onClick={e => onFilterReset(e)} className='btn btn-primary ml-1'>重置</button>
            </div>
        </form>
        <div>
            <h2>统计数据：</h2>
            <table className="statistics-table my-2">
                <thead className="statistics-table-head">
                    <tr className="statistics-table-item">
                        <th scope='col' className="statistics-table-inTransactions">总入库（个）</th>
                        <th scope='col' className="statistics-table-inTotalPrice">入库总价（元）</th>                    
                        <th scope='col' className="statistics-table-inShippingCost">入库总运输成本（元）</th>
                        <th scope='col' className="statistics-table-outTransactions">总出库（个）</th>
                        <th scope='col' className="statistics-table-outTotalPrice">出库总价（元）</th>
                        <th scope='col' className="statistics-table-outShippingCost">出库总运输成本（元）</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="statistics-table-item">
                        <td scope='col' className="statistics-table-inTransactions">{transactionsStatistic.inTransactions}</td>
                        <td scope='col' className="statistics-table-inTotalPrice">{transactionsStatistic.inTotalPrice}</td>
                        <td scope='col' className="statistics-table-inShippingCost">{transactionsStatistic.inShippingCost}</td>
                        <td scope='col' className="statistics-table-outTransactions">{transactionsStatistic.outTransactions}</td>
                        <td scope='col' className="statistics-table-outTotalPrice">{transactionsStatistic.outTotalPrice}</td>
                        <td scope='col' className="statistics-table-outShippingCost">{transactionsStatistic.outShippingCost}</td>
                    </tr>  
                </tbody>
            </table>
        </div>
        <TransactionsList />
        {/* transactions: {JSON.stringify(transactions)} */}
    </div>
  : <WaitingSpinner />
}

Transactions.propTypes = {
    transactions: PropTypes.array.isRequired,
    getTransactions: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    transactionsLoading: PropTypes.bool.isRequired,
    item: PropTypes.object,
    itemLoading: PropTypes.bool.isRequired,
    newTransaction: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    clearItem: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    usersLoading: PropTypes.bool.isRequired,
    filterTransactions: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    item: state.item.item,
    itemLoading: state.item.itemLoading,
    transactions: state.transaction.transactions,
    transactionsLoading: state.transaction.transactionsLoading,
    isAuthenticated: state.auth.isAuthenticated,
    users: state.user.users,
    usersLoading: state.user.usersLoading
})

export default connect(mapStateToProps, {getTransactions, getItem, newTransaction, clearItem, getAllUsers, filterTransactions})(Transactions)