import axios from "axios";
// import { setAlert } from "./alert";

import {
    GET_TRANSACTION,
    GET_TRANSACTIONS,
    TRANSACTION_ERROR,
    TRANSACTIONS_ERROR,
    NEW_TRANSACTION,
    APPROVE_TRANSACTION,
    DELETE_TRANSACTION,
    GET_AGENT_TRANSACTIONS
} from './types';
import { setAlert } from "./alert";

// NEW TRANSACTION
export const newTransaction = (formdata) => async dispatch => {
    console.log(formdata);
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

    const body = JSON.stringify(formdata);

    try{
        const item = await axios.get(`/api/items/${formdata.item}`)
        if(formdata.activity === 'out'&& item && item.numInStock < formdata.count){
            dispatch(setAlert('库存不足，无法操作!', 'danger'))
        }else{
            const res = await axios.post(
                // 'http://localhost:5000/api/transactions', 
                '/api/transactions',
                body, 
                config);
            
            dispatch({
                type: NEW_TRANSACTION,
                payload: res.data
            })
        }
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })      
    }
}

// GET SINGLE TRANSACTION 
export const getTransaction = (id) => async dispatch => {
    try {
        const res = await axios.get(`/api/transactions/${id}`);
        // const res = await axios.get(`http://localhost:5000/api/transactions/${id}`);
        // console.log('getTransaction called in action');

        dispatch({
            type: GET_TRANSACTION,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })
    }
    
    document.getElementById('waiting-spinner').classList.add('display-none')
}

// GET TRANSACTIONS
export const getTransactions = () => async dispatch => {
    try {
        const res = await axios.get('/api/transactions');

        dispatch({
            type: GET_TRANSACTIONS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })
    }
}

// FILTER TRANSACTIONS
export const filterTransactions = (formData) => async dispatch => {
    // console.log(formData);
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

    const body = JSON.stringify(formData);
    console.log(body);
    try {
        // const { startDate, endDate, operator, acitvity, channel } = formData;

        const res = await axios.post(
            '/api/transactions/filter',
            body, 
            config);
        dispatch({
            type: GET_TRANSACTIONS,
            payload: res.data
        })
    }catch(err){
        dispatch({
            type: TRANSACTION_ERROR,
            payload: err.response
        })
    }
}


// DELETE TRANSACTION
// 这个部分暂且不用，只设定入库、出库两种行为，有取消原有出库行为的需求就进行再次入库
export const deleteTransaction = (id) => async dispatch => {
    // console.log("deleteTransaction called in action.")
      try{
          const res = await axios.delete(`/api/transactions/${id}`);
          // await axios.delete(`http://localhost:5000/api/transactions/${id}`);
  
          dispatch({
              type: DELETE_TRANSACTION,
              payload: id
          });
        //   dispatch(setAlert(res.data.msg, "success"));
      }catch (err) {
          dispatch({
            type: TRANSACTION_ERROR,
            payload: {
              msg: err.response.statusText,
              status: err.response.status
            }
          });
        }
        document.getElementById('waiting-spinner').classList.add('display-none')
  }

//  CREATE NEW TRANSACTION (AUTOMATICLY GENERATED)
