import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

// Utilities
import setAuthToken from "./utilities/setAuthToken";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// Components

import WaitingSpinner from "./components/layout/WaitingSpinner";
import Alert from './components/layout/Alert';
import Home from './components/pages/Home';
import Main from './components/pages/Main';
import Items from './components/pages/Items';
import Transactions from './components/pages/Transactions';

// Actions
import { loadUser } from "./actions/user";
import { getItems } from "./actions/item";

function App() {
  const token = localStorage.getItem("IOtoken")
  
  console.log(token);
  useEffect(() => {
    if(token){
      store.dispatch(loadUser())
    };;
    store.dispatch(getItems())
  },[loadUser, getItems]);

   return (
    <Provider store = {store}>
      <Router>
          {/* <Navbar /> */}
          <Alert /> 
          <Routes>
            <Route exact path='/' element = {<Home />} />
            <Route exact path='/main' element = {<Main />} />
            <Route exact path="/items" element = {<Items />} />
            <Route exact path="/transactions" element={<Transactions />} />
          </Routes>
          {/* <Footer /> */}
          <WaitingSpinner />
      </Router>
    </Provider>
  );
}

export default App;
