import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Resizer from "react-image-file-resizer";

import { getItems, newItem, uploadFiles } from '../../actions/item'

import ItemsListItem from '../layout/ItemsListItem'
import WaitingSpinner from '../layout/WaitingSpinner'
import ImagePlaceHolder from '../layout/imgPlaceholder.png'
import { setAlert } from '../../actions/alert'

const Items = ({items, itemsLoading, getItems,uploadFiles, isAuthenticated, setAlert, newItem}) => {
    const navigate = new useNavigate();
    useEffect(()=>{
        setTimeout(()=>{
            if(isAuthenticated){getItems();}else{ navigate('/') }
        }, 1000)
    },[getItems])

    useEffect(()=>{console.log(items)},[items, itemsLoading])

    const [ formData, setFormData ] = useState({
        coverImg: '',
        barCode: '',
        acgName: '',
        figure: '',
        title: '',
        numInStock: 0
    })

    const [ imgToUpload, setImgToUpload ] = useState({coverImg: null})
    const { coverImg } = imgToUpload;

    useEffect(()=>{
        // const reader = new FileReader();
        // const photoPreview = document.getElementById('new-item-form-coverImg-preview');
        // if(photoPreview){
        //     reader.addEventListener("load", function(){
        //         photoPreview.src=reader.result;
        //         Resizer.imageFileResizer(imageFile, 600, 600, "png", 100, 0, (uri) => {
        //             ssetImgToUpload({...imgToUpload, coverImg: file});
        //             setFormData({
        //                 ...formData,
        //                 coverImg: uri
        //             });
        //         })
        //     })
        //     if(coverImg && photoPreview){
        //         reader.readAsDataURL(coverImg);
        //         photoPreview.src = URL.createObjectURL(coverImg);
        //     }else{
        //         photoPreview.src = ImagePlaceHolder
        //     }
        // }

        // console.log(formData, coverImg)
    },[formData, imgToUpload])

    const onFormChange = e => {
        const field = e.target.name.split('-')[4];
        console.log(field);
        // switch(field){
        //     case 'coverImg':
                
        //         const file = e.target.files[0];
        //         setFormData({
        //             ...formData,
        //             coverImg: file.name
        //         });
                
        //         return;
        //     default:
        setFormData({
            ...formData,
            [field]:e.target.value
        });
                // return;

        // }
    }

    const onImageChange = e => {
        e.preventDefault();
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        const photoPreview = document.getElementById('new-item-form-coverImg-preview');
        // console.log(photoPreview, imageFile)
        if(photoPreview && imageFile){
            reader.addEventListener("load", function(){
                photoPreview.src=reader.result;
                Resizer.imageFileResizer(imageFile, 600, 600, "png", 100, 0, (uri) => {
                    console.log('uri:', uri)
                    setImgToUpload({...imgToUpload, coverImg: uri});
                    setFormData({
                        ...formData,
                        coverImg: uri.name
                    });
                }, 'file')
            })
            if(photoPreview){
                reader.readAsDataURL(imageFile);
                photoPreview.src = URL.createObjectURL(imageFile);
            }else{
                photoPreview.src = ImagePlaceHolder
            }
        }
    }

    const clearNewItemForm = () => {
        // if(!){
            document.getElementById('new-item-form-input-coverImg').value = '';
            document.getElementById('new-item-form-coverImg-preview').src = ImagePlaceHolder;
            setFormData({
                ...formData,
                coverImg: '',
                barCode: '',
                acgName: '',
                figure: '',
                title: '',
                numInStock: 0
            })
            setImgToUpload({
                ...imgToUpload,
                coverImg: null
            })
        // }
    }

    const onsubmit = e=> {
        e.preventDefault();
        if(formData.coverImg !== '' && formData.barCode !== '' && formData.acgName !== '' && formData.figure !== '' && formData.title !== ''){
            console.log(formData, imgToUpload) 
            newItem(formData);
            setTimeout(()=>{
                clearNewItemForm()
            },1000)
        }else{
            setAlert('请检查表格内信息，所有内容均为必填项！', 'danger')
        }
        if(imgToUpload.coverImg && formData.barCode){
            const filesFormData = new FormData()
            filesFormData.append('coverImg', imgToUpload.coverImg);

            setTimeout(()=>{
                console.log(filesFormData);
                uploadFiles(filesFormData, formData.barCode);
            }, 500)
        }
    }



  return !itemsLoading ?
    <div className='container'>
        <Link to='/main' className='navigate-link'><FontAwesomeIcon icon={faArrowLeft}/> 返回</Link>
        <form id="new-item-form" className="new-item-form">
            
            <h2 className='section-title' >新建商品</h2>
            <table className="new-item-table">
                <thead className="new-item-table-head">
                    <tr className="items-table-item">
                        <th scope='col' className="new-item-table-item-coverImg">图片</th>
                        <th scope='col' className="new-item-table-item-barCode">条形码</th>
                        <th scope='col' className="new-item-table-item-acgName">番名</th>
                        <th scope='col' className="new-item-table-item-figure">人物</th>
                        <th scope='col' className="new-item-table-item-title">名称</th>
                        <th scope='col' className="new-item-table-item-barCode">库存</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="new-item-table-item">
                        <td scope='col' className="new-item-table-coverImg">
                            <input type="file" name="new-item-form-input-coverImg" id="new-item-form-input-coverImg" onChange={e => onImageChange(e)}/>
                        </td>
                        <td scope='col' className="new-item-table-item-barCode">
                            <input type="text" name="new-item-form-input-barCode" id="new-item-form-input-barCode" value={formData.barCode} onChange={e => onFormChange(e)} required/>
                        </td>
                        <td scope='col' className="new-item-table-item-acgName">
                            <input type="text" name="new-item-form-input-acgName" id="new-item-form-input-acgName" value={formData.acgName} onChange={e => onFormChange(e)} required/>
                        </td>
                        <td scope='col' className="new-item-table-item-figure">
                            <input type="text" name="new-item-form-input-figure" id="new-item-form-input-figure" value={formData.figure} onChange={e => onFormChange(e)} required/>
                        </td>
                        <td scope='col' className="new-item-table-item-title">
                            <input type="text" name="new-item-form-input-title" id="new-item-form-input-title" value={formData.title} onChange={e => onFormChange(e)} required/>
                        </td>
                        <td scope='col' className="new-item-table-item-numInStock">
                            <input type="number" name="new-item-form-input-numInStock" id="new-item-form-input-numInStock" value={formData.numInStock} onChange={e => onFormChange(e)} required/>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="display-flex">
                <img className='new-item-form-coverImg-preview' src={ImagePlaceHolder} alt="图片预览" id='new-item-form-coverImg-preview'/>
                <button className="ml-2 new-item-form-submit-btn" onClick={e => onsubmit(e)}>新建商品</button>
            </div>
        </form>
        <h2 className='section-title' >库存商品列表</h2>
        <table className="items-table">
            <thead className="items-table-head">
                <tr className="items-table-item">
                    <th scope='col' className="items-table-item-coverImg">图片</th>
                    <th scope='col' className="items-table-item-barCode">条形码</th>
                    <th scope='col' className="items-table-item-acgName">番名</th>
                    <th scope='col' className="items-table-item-figure">人物</th>
                    <th scope='col' className="items-table-item-title">名称</th>
                    <th scope='col' className="items-table-item-barCode">库存</th>
                </tr>
            </thead>
            <tbody>
                {items.length > 0 ? items.map(item => 
                    <ItemsListItem item={item}  key={item._id}/>
                ) : ''}  
            </tbody>
        </table>
        
    </div>
  : <WaitingSpinner /> 
}

Items.propTypes = {
    items: PropTypes.array.isRequired,
    itemsLoading: PropTypes.bool.isRequired,
    getItems: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    setAlert: PropTypes.func.isRequired,
    newItem: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    items: state.item.items,
    itemsLoading: state.item.itemsLoading,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {getItems, setAlert, newItem, uploadFiles})(Items)