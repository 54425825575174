import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import Moment from 'react-moment';


const TransactionsList = ({transactions}) => {

    const activityDB = {
        'in': '入库',
        'out': '出库'
    }
  return (
    <table className="transactions-table mb-2">
            <thead className="transactions-table-head">
                <tr className="transactions-table-item">
                    <th scope='col' className="transactions-table-item">日期</th>
                    <th scope='col' className="transactions-table-item">条形码</th>
                    <th scope='col' className="transactions-table-item">数量</th>
                    <th scope='col' className="transactions-table-item">价格</th>
                    <th scope='col' className="transactions-table-item">邮费</th>  
                    <th scope='col' className="transactions-table-item">渠道</th>                    
                    <th scope='col' className="transactions-table-item">操作员</th>
                    <th scope='col' className="transactions-table-item">出入库</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map(transaction => <tr className="items-table-item" key={transaction._id}>
                    <td scope='col' className="transactions-table-item"><Moment format='YYYY年MM月DD日 HH:MM:SS'>{transaction.date}</Moment></td>
                    <td scope='col' className="transactions-table-item">{transaction.item}</td>
                    <td scope='col' className="transactions-table-item">{transaction.count}</td>
                    <td scope='col' className="transactions-table-item">{transaction.price}</td>
                    <td scope='col' className="transactions-table-item">{transaction.shippingCost}</td>
                    <td scope='col' className="transactions-table-item">{transaction.channel ? transaction.channel :''}</td>
                    <td scope='col' className="transactions-table-item">{transaction.operator}</td>
                    <td scope='col' className={transaction.activity === 'in' ? 'transactions-table-item text-blue' : 'transactions-table-item text-green'}>{activityDB[transaction.activity]}</td>
                </tr>)}  
            </tbody>
        </table>
  )
}

TransactionsList.propTypes = {
    transactions: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    transactions: state.transaction.transactions
})

export default connect(mapStateToProps)(TransactionsList)