import React from 'react'

// 引入模块
import Login from '../layout/Login'

export default () => {
  return (
    <div className='container'>
      <Login action={'login'}/>
    </div>
  )
}