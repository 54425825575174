import axios from "axios";
import {setAlert } from './alert';

import {
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_USER
  } from "./types";
//   import setAuthToken from "../utilities/setAuthToken";
  import { loadUser } from "./user";


// Login User
export const login = (username, password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  
    const body = JSON.stringify({ username, password });
    // console.log(body);
  
    try {
      const res = await axios.post(
        // "http://localhost:5000/api/auth",
        '/api/auth',
        body,
        config
      );

      // console.log('login succeed: ', res.data)
  
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      dispatch(loadUser());

      // document.getElementById('login-box').classList.add('display-none')
  
      // dispatch(loadUser());
    } catch (err) {
      console.log(err.response);
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch(setAlert("口令错误", "danger"));
    }
  };
  

// Logout and Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_USER})
};

