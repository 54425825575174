import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import transaction from './transaction';
import item from './item';
import user from './user';

export default combineReducers({
    alert,
    auth,
    user,
    item,
    transaction
})