import {
    GET_TRANSACTION,
    GET_TRANSACTIONS,
    TRANSACTION_ERROR,
    NEW_TRANSACTION,
    DELETE_TRANSACTION
} from '../actions/types'

const initialState = {
    transaction: null,
    transactions: [],
    transactionLoading: null,
    transactionsLoading: true,
    error: null
}

export default function(state=initialState, action){
    const {type, payload} = action;

    switch(type){
        case GET_TRANSACTION:
            return {
                ...state,
                transactionLoading: false,
                transaction: payload
            }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactionsLoading: false,
                transactions: payload ? payload : []
            }
        case NEW_TRANSACTION:
            console.log(payload)
            return {
                ...state,
                transactionsLoading: false,
                transactions: [payload, ...state.transactions],
                transactionLoading: false,
                transaction: payload
            }
        case DELETE_TRANSACTION:
            return {
                ...state,
                transactionLoading: true,
                transaction: null,
                transactionsLoading: false,
                transactions: state.transactions.filter(item => item !== payload)
            }
        case TRANSACTION_ERROR:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}